import React from 'react'
import { Hero } from "../../Components";

const Lagos = () => {
  return (
    <div>
      <Hero
        heroheading="PROJECTS"
        herosubheading="Light Up Lagos"
        text="Explore our portfolio of remarkable Integrated Infrastructure Asset Management projects."
        bg="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1684768421/imeassets/lightup_lagos_project_ouopm2.jpg"
      />


<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168712/imeassets/projects/light%20up%20lagos/WhatsApp_Image_2023-07-15_at_13.04.12_ebcgv2.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0048_pr0t7a.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/WhatsApp_Image_2023-07-15_at_11.23.35_jahwas.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0049_ghckln.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0050_lhzeeg.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0046_roev48.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0047_exf2el.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0044_bpgoth.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0045_vghn3t.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0042_kfe7yo.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
        <img
              src="https://res.cloudinary.com/dz2fc3ioz/image/upload/v1691168711/imeassets/projects/light%20up%20lagos/IMG-20230717-WA0043_w3xnxo.jpg"
              className="object-cover w-full h-64 rounded"
              alt=""
            />
        </div>
      </div>
    </div>

    </div>
  )
}

export default Lagos